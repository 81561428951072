<template>
  <b-card no-body title="Users">
    <div>
      <b-button-toolbar justify class="toolbar-top-margin">
        <b-form-group label-align-sm="left" label-size="md" label-for="filterInput" class="mb-0 search-bar-margin">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filterBy" type="search" placeholder="Type to Search" debounce="500" />
          </b-input-group>
        </b-form-group>
        <b-button-group class="mr-2 mb-1" vertical>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" class="btn-icon" @click="initalLoadMetrc">
            <b-spinner v-if="getLoading" small style="margin-bottom: 3px" />
            <feather-icon icon="RefreshCwIcon" v-if="!getLoading" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </div>
    <b-overlay :show="getLoading" rounded="sm" opacity="0.8">
      <b-table responsive :fields="fields" :items="getAllMetrcStatus" :current-page="currentPage">
        <template #cell(facilityName)="data">
          <b-link v-model="data.detailsShowing" @click="fetchDetails(data)">
            {{ data.value }}
          </b-link>
          <br />
          <small
            >{{ data.item.facilityLicense }} (expires: {{ data.item.facilityLicenseExpires }})
            <template v-if="data.item.facilityDba"><br />dba: {{ data.item.facilityDba }}</template>
          </small>
        </template>
        <template #cell(syncEnabled)="data">
          <b-form-checkbox :checked="data.value" class="custom-control-success" name="active-button" @change="toggleSync($event, data.item)" switch />
        </template>
        <template #cell(inProgress)="data">
          <template v-if="data.value">
            <feather-icon icon="PlayCircleIcon" class="text-success mr-1" size="25" />
          </template>
          <template v-else>
            <feather-icon icon="StopCircleIcon" class="text-danger mr-1" size="25" />
          </template>
        </template>

        <template #cell(lastMsg)="data">
          <template v-if="data.value"> {{ data.value }}<br /> </template>
          <template v-if="data.item.lastCount > 0"> {{ data.item.lastCount }} rows fetched. </template>
        </template>
        <template #cell(lastSync)="data">
          <template v-if="data.value">
            {{ showDateInFormat(data.value) }}
          </template>
        </template>
        <!-- details -->
        <template #row-details="row">
          <b-card>
            <b>API Keys for facility {{ row.facilityName }}</b>
            <br />
            <b-table responsive :fields="apiKeyFields" :items="facilityKeyData">
              <template #cell(valid)="data">
                <span>
                  <template v-if="data.value">
                    <feather-icon icon="CheckCircleIcon" size="24" class="text-success"></feather-icon>
                  </template>
                  <template v-else>
                    <feather-icon icon="XCircleIcon" size="24" class="text-danger"></feather-icon>
                  </template>
                </span>
              </template>
            </b-table>
          </b-card>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <div class="col-1">
          <b-form-select id="perPageSelect" :value="getPerPage" size="sm" inline :options="pageOptions" @change="perPageChanged" />
          <p class="text-nowrap mb-md-0 mr-1"><b>Total rows:</b> {{ getTotalRows }}</p>
        </div>

        <!-- pagination -->
        <div>
          <b-pagination
            :value="currentPage"
            :total-rows="getTotalRows"
            :per-page="getPerPage"
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            limit="1"
            hide-ellipsis
            hide-goto-end-buttons
            @change="onPageChanged"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  getAllMetrcStatus,
  filterBy,
  setPerPage,
  getLoading,
  onLoadAllMetrcSyncStatus,
  getTotalRows,
  fetchMetrcFacilityInfo,
  getPerPage,
  toggleMetrcSync
} from "@/views/metrc/metrcRequests";
import { DEFAULT_PAGE_OPTIONS } from "@/listo/defaults";
import { onMounted, ref, watch } from "@vue/composition-api";

import Ripple from "vue-ripple-directive";
import moment from "moment";

export default {
  name: "AllMetrc",
  components: {
    Ripple
  },
  directives: {
    Ripple
  },
  setup(_, { root }) {
    const currentPage = ref(1);
    const pageOptions = DEFAULT_PAGE_OPTIONS;
    const fields = [
      {
        key: "facilityName",
        label: "Name"
      },

      {
        key: "syncEnabled",
        label: "Sync Enabled",
        thClass: "text-center",
        tdClass: "text-center"
      },
      {
        key: "inProgress",
        label: "In Progress",
        thClass: "text-center",
        tdClass: "text-center"
      },
      {
        key: "syncType",
        label: "Metrc Type"
      },
      { key: "lastSync", label: "Last Run" },
      { key: "lastMsg", label: "Last Status" }
    ];

    const apiKeyFields = [
      { key: "staffName", label: "Full Name" },
      { key: "staffUsername", label: "Username" },
      {
        key: "apiKey",
        label: "API Key",
        formatter: (value) => {
          if (!value) {
            return "";
          }
          return value.substring(0, 4) + "..." + value.substring(value.length - 4);
        }
      },
      { key: "valid", label: "Valid" }
    ];

    watch(filterBy, (newValue, oldValue) => {
      initalLoadMetrc();
    });

    const showDateInFormat = (date) => moment(date).format("YYYY-MM-DD hh:mm:ss a");

    const initalLoadMetrc = async () => onLoadAllMetrcSyncStatus({ prevPage: false, nextPage: false, reset: true });

    const perPageChanged = async (val) => {
      setPerPage(val);
      await initalLoadMetrc();
    };

    const onPageChanged = async (val) => {
      if (val == 1) {
        currentPage.value = val;
        await onLoadAllMetrcSyncStatus({ prevPage: false, nextPage: false, reset: true });
        return;
      }
      if (currentPage.value > val) {
        await onLoadAllMetrcSyncStatus({ prevPage: true, nextPage: false });
        currentPage.value = val;
        return;
      }
      await onLoadAllMetrcSyncStatus({ prevPage: false, nextPage: true });
      currentPage.value = val;
    };

    onMounted(() => {
      filterBy.value = "";
    });

    const facilityKeyData = ref({});

    const fetchDetails = async (row) => {
      if (row.item._showDetails) {
        row.toggleDetails();
        facilityKeyData.value = null;
        return;
      }
      const data = await fetchMetrcFacilityInfo(row.item.facilityUid);

      facilityKeyData.value = data.metrcApiKeys;
      row._showDetails = !row._showDetails;
      row.toggleDetails();
    };

    const toggleSync = async (newVal, item) => {
      let req = {};
      req.forFacilityUid = item.facilityUid;
      req.active = newVal;
      try {
        await toggleMetrcSync(req);
      } catch (err) {
        let respMsg = "";
        if (err.response) {
          respMsg = err.response.data && err.response.data.error ? err.response.data.error : err.response.data.message;
        }
        respMsg = respMsg === "" ? "Unknown Error" : respMsg;
        root.$bvToast.toast(`Message: ${respMsg}`, {
          title: `Updating Metrc Sync Status for ${item.facilityName} Failed`,
          toaster: "b-toaster-bottom-center",
          variant: "danger",
          autoHideDelay: 5000
        });
        await initalLoadMetrc();
      }
    };

    return {
      toggleSync,
      getAllMetrcStatus,
      filterBy,
      setPerPage,
      getLoading,
      onLoadAllMetrcSyncStatus,
      getTotalRows,
      getPerPage,
      initalLoadMetrc,
      fields,
      currentPage,
      pageOptions,
      showDateInFormat,
      onPageChanged,
      perPageChanged,
      facilityKeyData,
      apiKeyFields,
      fetchDetails
    };
  }
};
</script>

<style scoped>
.toolbar-top-margin {
  margin-top: 10px;
}
.search-bar-margin {
  margin-left: 15px;
}
.new-only-switch {
  margin-left: 20px;
}
</style>
