import { ref, computed } from "@vue/composition-api";
import { requestLoadMetrcStatus, requestToggleMetrcSync, requestMetrcKeyInfo } from "@/service/MetrcService";

const metrcStatus = ref([]);
export const filterBy = ref(null);
const perPage = ref(10);
const loading = ref(false);
const totalRows = ref(0);

let lastPageItem = 0;
let firstPageItem = 0;

export const setPerPage = (val) => (perPage.value = val);
export const getPerPage = computed(() => perPage.value);
export const getLoading = computed(() => loading.value);
export const getTotalRows = computed(() => totalRows.value);
export const getAllMetrcStatus = computed(() => metrcStatus.value);

export const fetchMetrcFacilityInfo = async (fid) => {
  try {
    loading.value = true;
    let req = {};
    req.forFacilityUid = fid;
    const { data } = await requestMetrcKeyInfo(req);
    return data;
  } finally {
    loading.value = false;
  }
};

export const toggleMetrcSync = async (req) => {
  try {
    loading.value = true;
    await requestToggleMetrcSync(req);
  } finally {
    loading.value = false;
  }
};

export const onLoadAllMetrcSyncStatus = async ({ prevPage, nextPage, reset }) => {
  let useLast = 0;
  if (!reset) {
    if (nextPage) {
      useLast = lastPageItem;
    }
    if (prevPage) {
      useLast = firstPageItem - 1;
    }
  }
  const reqData = {
    lastItem: useLast,
    perPage: perPage.value,
    filter: {
      search: filterBy.value || null
    }
  };
  loading.value = true;
  try {
    const {
      data: { metrcSyncStatuses, pagination }
    } = await requestLoadMetrcStatus(reqData);

    if (metrcSyncStatuses) {
      metrcStatus.value.splice(0, metrcStatus.value.length);
      metrcStatus.value.push(...metrcSyncStatuses);
    } else {
      metrcStatus.value.splice(0, metrcStatus.value.length);
    }
    lastPageItem = pagination.lastItem;
    firstPageItem = pagination.firstItem;
    totalRows.value = pagination.totalRows ? pagination.totalRows : 10;
  } finally {
    loading.value = false;
  }
};
