import axiosIns from "@/libs/axios";

export function requestLoadMetrcStatus(reqData) {
  return axiosIns.post("/admin/load_metrc_status", reqData, {});
}

export function requestToggleMetrcSync(reqData) {
  return axiosIns.post("/admin/update_facility_metrc_sync", reqData, {});
}

export function requestMetrcKeyInfo(reqData) {
  return axiosIns.post("/admin/metrc_key_info", reqData, {});
}
